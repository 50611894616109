import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCrops, getSubscriberCrops, setActiveCrop, setAddCropsDrawer, setSelectedCrops, setSowingDateModal, updateSubcriberCropsLoading } from './store';
import { Spinner } from 'react-bootstrap';
import CropDetails from './components/CropDetails';
import AddCropSection from './components/AddCropSection';
import AddCrop from './modals/AddCrop';
import GenericModal from '../../../components/Modals/GenericModal/GenericModal';
import SowingDateDrawer from './modals/SowingDateDrawer';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';

const CropSection = ({ currentWeather, handleClickLocationModal }) => {
    const dispatch = useDispatch();
    const authStore = useSelector((state) => state.auth);
    const subscriptionStore = useSelector((state) => state.Subscription);
    const cropStore = useSelector((state) => state.CropsStore);

    const [rerender, setRerender] = useState(false)

    const [confirmationAddCropModal, setConfirmationAddCropModal] = useState(false)
    const [cropTitles, setCropTitles] = useState("")

    useEffect(() => {
        if (!cropStore.selectedCrops) return
        setCropTitles(cropStore.selectedCrops.map(crop => crop.urduName).join("، "))
    }, [cropStore.selectedCrops])

    useEffect(() => {
        dispatch(getCrops({ province: subscriptionStore?.subscriberDetails?.data?.province }));
    }, [subscriptionStore?.subscriberDetails?.data?.province]);

    const cellNo = authStore?.authData?.msisdn;
    useEffect(() => {
        if (authStore.authData.msisdn === "03400000000") {
            if (cropStore.subscriberCrops.length > 0 && !cropStore.activeCrop) dispatch(setActiveCrop(cropStore.subscriberCrops[0]))
            dispatch(updateSubcriberCropsLoading(false))
            return;
        }
        dispatch(getSubscriberCrops({ cellno: cellNo }));
    }, [cellNo, rerender])

    const handleAddCropDrawer = () => {
        if (!currentWeather?.conditionDesc) {
            handleClickLocationModal(true);
            return;
        }
        dispatch(setAddCropsDrawer(true))
    }

    if (cropStore?.isSubscriberCropsLoading) return <div className="flex justify-center items-center h-80 bg-white rounded mt-3">
        <Spinner />
    </div>
    else return (
        <>
            <div className='mt-3'>
                {
                    cropStore?.subscriberCrops?.length > 0 ?
                        <CropDetails
                            subscriberCrops={cropStore?.subscriberCrops}
                            activeCrop={cropStore?.activeCrop}
                            handleAddCropDrawer={handleAddCropDrawer}
                        />
                        :
                        <AddCropSection
                            handleAddCropDrawer={handleAddCropDrawer}
                        />
                }
            </div>


            {/* ============================= Add Crops Drawer ========================== */}
            <GenericModal
                isOpen={cropStore.addCropsDrawer}
                onClose={() => dispatch(setAddCropsDrawer(false))}
            >
                <AddCrop
                    allCrops={cropStore.allCrops}
                    setDrawer={() => dispatch(setAddCropsDrawer(false))}
                    activeCrop={cropStore?.activeCrop}
                    activeTab={cropStore.activeTab}
                    selectedCrops={cropStore.selectedCrops}
                    subscriberCrops={cropStore.subscriberCrops}
                    cellNo={cellNo}
                    rerender={rerender}
                    setRerender={setRerender}
                    setConfirmationAddCropModal={setConfirmationAddCropModal}
                />
            </GenericModal>

            {/* ============================= Sowing Date Drawer ========================== */}
            <GenericModal
                isOpen={cropStore.sowingDateDrawer}
                onClose={() => dispatch(setSowingDateModal(false))}
            >
                <SowingDateDrawer
                    cellNo={cellNo}
                    rerender={rerender}
                    setRerender={setRerender}
                />
            </GenericModal>

            {/* ================== Confirmation dialog to remove crop ================== */}
            <ConfirmationModal
                show={confirmationAddCropModal}
                handleClick={() => {
                    setConfirmationAddCropModal(false);
                    setRerender(!rerender)
                }}
                onHide={() => {
                    setConfirmationAddCropModal(false);
                    setRerender(!rerender)
                }}
                title={cropTitles}
                description={`آپ نے ${cropTitles} کی فصل منتخب کرلی ہے۔`}
            />
        </>
    )
}

export default CropSection