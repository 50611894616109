import axios from "axios";
import urduMonthsConfig from "../configs/urdu.months.config.json";
import moment from "moment";
export const formatDateWithUrduMonth = (date) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const [year, month, day] = formattedDate.split("-");
  const urduMonth = urduMonthsConfig[month];
  return `${day}-${urduMonth}-${year}`;
};

export const getVimeoVideoIdFromUrl = (url) => {
  const regex = /\/(\d+)\//;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const getVimeoThumbnail = async (videoId) => {
  // const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
  // const data = await response.json();
  // return data[0].thumbnail_large; // You can also use other sizes like thumbnail_small, thumbnail_medium, etc.
  return axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`)
    .then((response) => {
      let details = {
        thumbnail: response.data[0].thumbnail_large,
        time: response.data[0].duration
      }
      // console.log("RESPONSE", response.data[0])
      return details;
    })
    .catch((error) => {
      // console.log("ERROR", error);
    });
};

export function isSowingDateInRange(startDate, endDate, sowingDate) {
  // Convert the input dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);
  const sowingDt = new Date(sowingDate);

  // Check if the currentDate lies between startDate and endDate
  return sowingDt >= start && sowingDt <= end;
}


const punjabiMonths = [
  { name: 'Chet', start: new Date('2024-03-15'), end: new Date('2024-04-14') },
  { name: 'Vaisakh', start: new Date('2024-04-15'), end: new Date('2024-05-14') },
  { name: 'Jeth', start: new Date('2024-05-15'), end: new Date('2024-06-14') },
  { name: 'Harh', start: new Date('2024-06-15'), end: new Date('2024-07-14') },
  { name: 'Sawan', start: new Date('2024-07-15'), end: new Date('2024-08-14') },
  { name: 'Bhadon', start: new Date('2024-08-15'), end: new Date('2024-09-14') },
  { name: 'Asu', start: new Date('2024-09-15'), end: new Date('2024-10-14') },
  { name: 'Katak', start: new Date('2024-10-15'), end: new Date('2024-11-14') },
  { name: 'Maghar', start: new Date('2024-11-15'), end: new Date('2024-12-14') },
  { name: 'Poh', start: new Date('2024-12-15'), end: new Date('2024-01-14') },
  { name: 'Magh', start: new Date('2024-01-15'), end: new Date('2024-02-14') },
  { name: 'Phagun', start: new Date('2024-02-15'), end: new Date('2024-03-14') }
];

export function getPunjabiDate() {
  // const start = new Date(startDate);
  // const end = new Date(endDate);
  const currentDate = new Date();

  // if (currentDate < start || currentDate > end) {
  //   return 'Date out of range';
  // }

  for (const month of punjabiMonths) {
    if (currentDate >= month.start && currentDate <= month.end) {
      const punjabiDay = Math.floor((currentDate - month.start) / (1000 * 60 * 60 * 24)) + 1;
      return `${punjabiDay}`;
      // return `${punjabiDay} ${month.name}`;
    }
  }

  return 'Date not found in Punjabi calendar range';
}

export const findWeatherForCurrentHour = (data) => {
  const currentHour = moment().startOf('hour');
  const result = data?.find(item => moment(item.DateTime).isSame(currentHour, 'hour'));
  return result ? result : data[0] || "Weather data not found for the current hour";
};

export const findTodayWeather = (data) => {
  const currentDay = moment().startOf('day');

  const result = data?.find(item => {
    const itemDate = moment(item.DateTime);
    return itemDate.isSame(currentDay, 'day');
  });

  return result || "Weather data not found for the current day";
};