import "./CropDetails.css";
import React, { useEffect, useRef, useState } from "react";
import stageImage from "../../assets/images/cotton.jpg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SpinnerComponent from "../../components/Spinner/Spinner";
import moment from "moment/moment";
import cropsConfig from "../../configs/urdu.crops.config.json";
import { formatDateWithUrduMonth, isSowingDateInRange } from "../../utils/extras";
import { getChallenges, getPhases } from "../../API";
import { useDidUpdate } from "@mantine/hooks";

const steps = [
  { label: "بوانی کا مرحلہ", description: "0-2 days", image: stageImage },
  { label: "پودوں کا مرحلہ", description: "0-2 days", image: stageImage },
  { label: "پھول کے مرحلے", description: "0-2 days", image: stageImage },
  { label: "پھل کی ترقی", description: "0-2 days", image: stageImage },
  { label: "کٹائی کا مرحلہ", description: "0-2 days", image: stageImage }
];

// Function to wrap digits in a span with class 'digit'
export function applyInterFontToDigits(str) {
  return str.replace(/\d/g, (digit) => `<span class="digit">${digit}</span>`);
}
// export function applyInterFontToDigits(str) {
//   // Split the string into an array of characters
//   const chars = str.split('');

//   // Map through each character and wrap digits with a span
//   return chars.map((char, index) => {
//     if (/\d/.test(char)) {
//       return (
//         <span key={index} className="digit">
//           {char}
//         </span>
//       );
//     }
//     return char;
//   });
// }

const calculateDaysDifference = (startDateStr, endDateStr) => {
  const startDate = moment(startDateStr);
  const endDate = moment(endDateStr);

  const differenceInDays = endDate.diff(startDate, 'days');
  return differenceInDays;
}

const CropDetails = () => {
  const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;
  const cropsStore = useSelector((store) => store.CropsStore);
  const subscriptionsStore = useSelector((store) => store.Subscription);
  const authStore = useSelector((store) => store.auth)
  const cellNo = authStore?.authData?.msisdn;
  const province = subscriptionsStore?.subscriberDetails?.data?.province;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(null)
  const [phases, setPhases] = useState(null)
  const [challenges, setChallenges] = useState(null)
  const [highlightedItemId, setHighlightedItemId] = useState(null)
  const itemRefs = useRef([]);

  useEffect(() => {
    if (highlightedItemId) {
      const index = phases.findIndex(item => item.id === highlightedItemId);
      if (index !== -1 && itemRefs.current[index]) {
        itemRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [highlightedItemId, phases]);

  useEffect(() => {
    getPhases(cropsStore?.cropObject?.cropId, province)
      .then(res => {
        // console.log("Phases -> ", res.data.data);
        let tempPhases = res.data.data.map(item => ({
          ...item,
          daysDifference: calculateDaysDifference(item.phaseDurationStartDate, item.phaseDurationEndDate)
        }))
        setPhases(tempPhases)
        let activeStep = res.data.data.find(item => isSowingDateInRange(item.phaseDurationStartDate, item.phaseDurationEndDate, cropsStore?.cropObject?.sowingDt))
        // console.log("Active Step -> ", activeStep)
        if (activeStep) {
          setActiveStep(activeStep)
          setHighlightedItemId(activeStep.id)
          return
        }
        setActiveStep(tempPhases[0])
        setHighlightedItemId(tempPhases[0].id)
      })
      .catch(err => {
        console.log("Error -> ", err);
      });
  }, [])

  useDidUpdate(() => {
    if (activeStep) {
      // console.log("Active Step -> ", activeStep)
      getChallenges(activeStep.id)
        .then(res => {
          // console.log("Challenges -> ", res.data.data);
          setChallenges(res.data.data)
        })
        .catch(err => {
          console.log("Error -> ", err);
        });
    }
  }, [activeStep])


  if (!phases) return <SpinnerComponent />;
  return (
    <>
      <div className="crop-details-page">
        {/* Header Section Start */}
        <div className="header-section">
          <div onClick={() => navigate('/home')} className="back cursor-pointer">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#0F2532" />
              <g clipPath="url(#clip0_811_8632)">
                <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_811_8632">
                  <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="header-content">
            <div className="left-side" style={{ direction: "rtl" }}>
              <p className="title">{cropsConfig[cropsStore?.cropObject?.cropId]}</p>
              {(cropsStore?.cropObject?.sowingDt || cropsStore?.cropObject?.sowingDate) && <p className="sowing-date" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(cropsStore?.cropObject?.sowingDt || cropsStore?.cropObject?.sowingDate)) }} />}
            </div>
            <div className="right-side">
              <img
                src={`${baseURL}/uploads/${cellNo !== "03400000000" ? cropsStore?.cropObject?.image : `${cropsStore?.cropObject?.cropId == "tobbaco" ? "tobacco" : cropsStore?.cropObject?.cropId}.png`}`}
                alt="stageImage"
              />
            </div>
          </div>
        </div>
        {/* Header Section End */}

        {/* Crops Staging Section Start */}
        <div className="stepper-container" style={{ overflowX: "auto", direction: "rtl" }}>
          <div className="stepper flex pl-2" style={{ width: `${steps.length * 100}px` }}>
            {phases?.map((item, index) => (
              <div ref={el => (itemRefs.current[index] = el)} key={index} className={`step ${activeStep?.id === item.id ? "active-step" : "in-active-step"}`}>
                <div className="step-number flex justify-center">
                  <div className={`${activeStep?.id === item.id ? 'number-active' : 'number'} `}>
                    <div className="digit">
                      {index + 1}
                    </div>
                  </div>
                </div>

                <div className="cursor-pointer step-content"
                  onClick={() => setActiveStep(item)}
                >
                  <img
                    className="image"
                    src={`${baseURL}/uploads/${item.image}`}
                    alt={item.label}
                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                  />
                  <p className="label">{item.phaseName}</p>
                  <p className="d-none description">{`(${item.daysDifference} - days)`}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Crops Staging Section End */}

        {/* Challenges Section Start */}
        <div className="stage-content">
          <div className="stage-info" style={{ direction: "rtl" }}>
            <p className="description flex flex-row-reverse gap-2 items-center">
              {/* {cropsConfig[cropsStore?.cropObject?.cropId]} */}
              آپ کی فصل کا مرحلہ: &nbsp;  {activeStep?.phaseName}
              {/* <img
                src="/info.svg"
                width={16}
              /> */}
              <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0.333984C3.32 0.333984 0.333336 3.32065 0.333336 7.00065C0.333336 10.6807 3.32 13.6673 7 13.6673C10.68 13.6673 13.6667 10.6807 13.6667 7.00065C13.6667 3.32065 10.68 0.333984 7 0.333984ZM7 10.334C6.63334 10.334 6.33334 10.034 6.33334 9.66732V7.00065C6.33334 6.63398 6.63334 6.33398 7 6.33398C7.36667 6.33398 7.66667 6.63398 7.66667 7.00065V9.66732C7.66667 10.034 7.36667 10.334 7 10.334ZM7.66667 5.00065H6.33334V3.66732H7.66667V5.00065Z" fill="#46bd4c" />
              </svg>

            </p>
          </div>

          <div className="product-challenges">
            <p className="title">
              {activeStep?.phaseName}
            </p>
            <div className="cards-container" style={{ padding: "0px 0px 70px 0px" }}>
              {challenges?.map((solution, index) => (
                <div className="single-card mt-3" key={index}>
                  <div className="card-content flex items-center justify-end">
                    <div className="left-side">
                      <p className="title mb-2" style={{ direction: "rtl" }}>{solution?.challengeName}</p>
                      <p className="description tracking-wider" style={{ direction: "rtl" }}>{solution.challengeDesc}</p>
                    </div>
                    <div className="d-none right-side">
                      <img src={stageImage} alt="product" />
                    </div>
                  </div>
                  <div className="card-button cursor-pointer" onClick={() => navigate(`/crop-details/solution/${solution?.id}`, { state: { challenge: solution } })}>
                    <p className="btn-title py-1 text-sm">مزید دیکھیں</p>
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.8334 5.33366H2.72002L6.44669 1.60699L5.50002 0.666992L0.166687 6.00033L5.50002 11.3337L6.44002 10.3937L2.72002 6.66699H10.8334V5.33366Z" fill="white" />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Challenges Section End */}

      </div >

    </>
  );
};

export default CropDetails;
