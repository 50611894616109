import React from 'react'
import { formatDateWithUrduMonth } from '../../../utils/extras';
import { applyInterFontToDigits } from '../../CropDetails/CropDetails';
import moment from 'moment';
const weatherBaseURL = process.env.REACT_APP_WEATHER_BASE_URL_DEVELOPMENT;
const WeatherModal = ({ weatherData, closeModal, currentWeather, currentDayWeather }) => {
    return (
        <div>
            {
                weatherData ? (
                    <div className="modal-container">
                        {/* Header */}
                        <div className="flex items-center justify-between">
                            <svg
                                className="cursor-pointer"
                                onClick={closeModal}
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.475 28.525C13.7958 28.8458 14.2042 29.0063 14.7 29.0063C15.1958 29.0063 15.6042 28.8458 15.925 28.525L21 23.45L26.1188 28.5688C26.4396 28.8896 26.8409 29.0424 27.3227 29.0272C27.8034 29.0132 28.2042 28.8458 28.525 28.525C28.8458 28.2042 29.0063 27.7958 29.0063 27.3C29.0063 26.8042 28.8458 26.3958 28.525 26.075L23.45 21L28.5688 15.8812C28.8896 15.5604 29.0424 15.1591 29.0272 14.6773C29.0132 14.1966 28.8458 13.7958 28.525 13.475C28.2042 13.1542 27.7958 12.9938 27.3 12.9938C26.8042 12.9938 26.3958 13.1542 26.075 13.475L21 18.55L15.8812 13.4313C15.5604 13.1104 15.1597 12.957 14.679 12.971C14.1972 12.9862 13.7958 13.1542 13.475 13.475C13.1542 13.7958 12.9938 14.2042 12.9938 14.7C12.9938 15.1958 13.1542 15.6042 13.475 15.925L18.55 21L13.4313 26.1188C13.1104 26.4396 12.9576 26.8403 12.9728 27.321C12.9868 27.8028 13.1542 28.2042 13.475 28.525ZM21 38.5C18.5792 38.5 16.3042 38.0403 14.175 37.121C12.0458 36.2028 10.1938 34.9563 8.61875 33.3813C7.04375 31.8063 5.79717 29.9542 4.879 27.825C3.95967 25.6958 3.5 23.4208 3.5 21C3.5 18.5792 3.95967 16.3042 4.879 14.175C5.79717 12.0458 7.04375 10.1938 8.61875 8.61875C10.1938 7.04375 12.0458 5.79658 14.175 4.87725C16.3042 3.95908 18.5792 3.5 21 3.5C23.4208 3.5 25.6958 3.95908 27.825 4.87725C29.9542 5.79658 31.8063 7.04375 33.3813 8.61875C34.9563 10.1938 36.2028 12.0458 37.121 14.175C38.0403 16.3042 38.5 18.5792 38.5 21C38.5 23.4208 38.0403 25.6958 37.121 27.825C36.2028 29.9542 34.9563 31.8063 33.3813 33.3813C31.8063 34.9563 29.9542 36.2028 27.825 37.121C25.6958 38.0403 23.4208 38.5 21 38.5Z"
                                    fill="#BABABA"
                                />
                            </svg>
                            <p
                                className="m-0"
                                style={{
                                    whiteSpace: "normal",
                                    direction: "rtl",
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                }}
                            >
                                آج کا موسم
                            </p>
                        </div>

                        {/* Weather Info */}
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex flex-col items-center justify-center">
                                <img className="w-[120px] h-[120px] rounded-full p-2 mt-3" src={`${weatherBaseURL}/${currentWeather?.WeatherIcon < 10 ? `0${currentWeather?.WeatherIcon}` : currentWeather?.WeatherIcon}.svg`} alt="weather-icon" />
                                {/* <img className="w-[85px] h-[85px] bg-gray-100 rounded-full p-2 mt-3" src="06.png" alt="weather-icon" /> */}
                                {/* <img className="w-[85px] h-[85px] rounded-full p-2 mt-3" src="Shower.svg" alt="weather-icon" /> */}
                                {/* <img className="w-[120px] h-[120px] rounded-full p-2 mt-3" src="Sunny.svg" alt="weather-icon" /> */}
                                {currentWeather?.DateTime && <p
                                    style={{
                                        whiteSpace: "normal",
                                        direction: "rtl",
                                        fontSize: "16px",
                                    }}
                                    className='mb-0'
                                    dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(currentWeather.DateTime)) }}
                                ></p>}
                                <h3 style={{ fontSize: "36", fontWeight: "bold" }}>
                                    <span className="digit">{Math.round(currentWeather?.Temperature?.Value)}°C</span>
                                </h3>
                            </div>

                            <div className="w-full weather-strip flex items-center justify-end">
                                <p className='mb-2 tracking-wide' style={{ whiteSpace: "normal", direction: "rtl" }}>{currentWeather?.conditionDescUrdu}</p>
                                <img className="w-[25px] h-[25px]" src={`${weatherBaseURL}/${currentWeather?.WeatherIcon < 10 ? `0${currentWeather?.WeatherIcon}` : currentWeather?.WeatherIcon}.svg`} alt="weather-icon" />
                            </div>
                        </div>

                        {/* Temperature Section */}
                        <div className="humidity-section flex items-center justify-between">
                            <div className="left-container">
                                <p className='para'>زیادہ سے زیادہ</p>
                                <span className='digit'>{(currentDayWeather?.Temperature?.Maximum?.Value)}°C</span>
                            </div>
                            {/* <Divider orientation="vertical" /> */}
                            <div className="right-container">
                                <p className='para'>کم سے کم</p>
                                <span className='digit'>{currentDayWeather?.Temperature?.Minimum?.Value}°C</span>
                            </div>
                        </div>

                        {/* Humidity Section */}
                        <div className="wind-section flex items-center justify-between">
                            <div className="left-container">
                                <p className="para mb-1">بارش کے امکانات</p>
                                <span className='digit'>{currentDayWeather?.Day?.PrecipitationProbability}%</span>
                            </div>
                            <div className="right-container">
                                {/* <p className="para">{currentDayWeather?.Day?.WindDescriptionUrdu}</p> */}
                                <p className="para">ہوا کی رفتار</p>
                                <span className='digit'>{currentDayWeather?.Day?.Wind?.Speed?.Value} km/h</span>
                            </div>
                        </div>

                        {/* Humidity Section */}
                        <div className="humidity-section flex items-center justify-between">
                            <div className="left-container">
                                <p className="para">نمی</p>
                                <span className='digit'>{currentDayWeather?.Day?.Humidity}%</span>
                            </div>
                            <div className="right-container">
                                <p className="para">UV انڈیکس</p>
                                <span className='digit'>High</span>
                            </div>
                        </div>

                        {/* Week Weather Section */}
                        <div className="week-weather-section flex items-center justify-between gap-3">
                            {
                                weatherData?.hourlyWeather?.HourlyForecasts?.map((item, index) => (
                                    <div className="container" key={index}>
                                        <p className="digit date text-gray-700 font-semibold" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(moment(item?.DateTime).format("HH:mm a")) }}></p>
                                        <img className="w-11 -mt-2 rounded-full p-1" src={`${weatherBaseURL}/${item?.WeatherIcon < 10 ? `0${item?.WeatherIcon}` : item?.WeatherIcon}.svg`} alt="weather-icon" />
                                        {/* <img className="w-10 -mt-2 rounded-full p-1" src="Shower.svg" alt="weather-icon" /> */}
                                        <span className='digit'>{Math.round(item?.Temperature?.Value)}°C</span>
                                    </div>
                                ))
                            }
                        </div>

                        {/* Next Several Days Weather Section */}
                        <div className="next-several-days-weather-section">
                            <h3 className="title">اگلے <span className="digit">7 </span>دن</h3>
                            <div className="cards-container">
                                {
                                    weatherData?.weeklyWeather?.slice(1, 8)?.map((item, index) => (
                                        <div className="single-card" key={index}>
                                            <div className="flex justify-between content">
                                                {item?.Date && <p className="text-xs font-semibold mt-2" style={{ direction: "rtl" }} dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(item.Date)) }}></p>}
                                                <div className="flex items-end flex-col">
                                                    <div className="flex items-center gap-1">
                                                        <span className="text-2xl">{item?.Temperature?.Maximum?.Value}°C</span>
                                                        <img className="w-9 h-9 p-[2px] rounded-full" src={`${weatherBaseURL}/${item?.Day?.Icon < 10 ? `0${item?.Day?.Icon}` : item?.Day?.Icon}.svg`} alt="weather-icon" />
                                                    </div>
                                                    <p className="description"> بارش کا امکان     &nbsp;<span className="digit font-semibold">%{item.Day.PrecipitationProbability}</span></p>
                                                </div>
                                            </div>
                                            <div className="weather-info-footer">
                                                <p style={{ whiteSpace: "normal", direction: "rtl" }}>
                                                    {item?.Day?.ConditionDescUrdu}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="modal-container flex items-center justify-center">Content not available</div>
                )
            }
        </div>
    )
}

export default WeatherModal