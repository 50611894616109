import React, { useEffect, useState } from 'react'
import { applyInterFontToDigits } from '../../CropDetails/CropDetails'
import { findTodayWeather, findWeatherForCurrentHour, formatDateWithUrduMonth, getPunjabiDate } from '../../../utils/extras'
import GenericModal from '../../../components/Modals/GenericModal/GenericModal'
import WeatherModal from '../../Home/components/WeatherModal'
import { getPunjabiMonth, getWeatherData } from '../../../API'
import { LoadingOverlay } from '@mantine/core'

const WeatherSection = () => {
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const weatherBaseURL = process.env.REACT_APP_WEATHER_BASE_URL_DEVELOPMENT;
    const [allWeatherData, setAllWeatherData] = useState({})
    const [currentWeather, setCurrentWeather] = useState({});
    const [currentDayWeather, setCurrentDayWeather] = useState({});
    const [punjabiDate, setPunjabiDate] = useState({
        date: "20",
        month: "ہاڑھ",
    })


    useEffect(() => {
        const promises = [
            getWeatherData(33.6744, 72.9485),
            getPunjabiMonth()
        ]
        // setLoading(true);
        Promise.all(promises)
            .then((responses) => {
                // console.log("Weather Data", responses[0].data.data);
                // console.log("Punjabi Month", responses[1].data.data);
                setAllWeatherData(responses?.[0]?.data?.data);
                let date = getPunjabiDate(responses?.[1]?.data?.data?.startDate, responses?.[1]?.data?.data?.endDate);
                setPunjabiDate({ date: date.toString(), month: responses[1].data.data.monthPunjabi });
                const weatherAtCurrentHour = findWeatherForCurrentHour(responses?.[0]?.data?.data?.hourlyWeather?.HourlyForecasts);
                const weatherAtCurrentCurrentDay = findTodayWeather(responses?.[0]?.data?.data?.weeklyWeather);
                setCurrentDayWeather(weatherAtCurrentCurrentDay);
                setCurrentWeather(weatherAtCurrentHour);
            })
            .catch((error) => {
                console.log("ERROR", error);
            })
            .finally(() => setLoading(false));
    }, [])
    return (
        <div className='relative'>
            <div className="weather-information cursor-pointer rounded bg-white mt-4"
                onClick={() => setIsModalOpen(true)}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center left-side w-1/4">
                        <div className="marquee-container">
                            <div className="marquee-content">
                                <p><span dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(punjabiDate.date) }}></span> {punjabiDate.month}</p>
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="flex items-center">
                            <p className="temperature">
                                <span className="digit">{currentWeather?.Temperature?.Value ? Math.round(currentWeather?.Temperature?.Value) : 32}°C</span>
                            </p>
                            {currentWeather.WeatherIcon ? <img
                                className="w-[39px] h-[39px]"
                                src={`${weatherBaseURL}/${currentWeather.WeatherIcon}.svg`}
                                alt="weather-icon"
                            /> :
                                <img
                                    className="w-[39px] h-[39px]"
                                    src={`cloud.png`}
                                    alt="weather-icon"
                                />}
                        </div>

                        <p className="date" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(currentWeather.DateTime || "2024-07-04T12:00:00+0500")) }}>
                            {/* {applyInterFontToDigits(formatDateWithUrduMonth(currentWeather.DateTime || "2024-07-04T12:00:00+0500"))} */}
                        </p>
                    </div>
                </div>
                <button className="card-btn">
                    <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.8334 5.33366H2.72002L6.44669 1.60699L5.50002 0.666992L0.166687 6.00033L5.50002 11.3337L6.44002 10.3937L2.72002 6.66699H10.8334V5.33366Z"
                            fill="white"
                        />
                    </svg>
                    موسم کی پیشن گوئی دیکھیں
                </button>
            </div>
            <LoadingOverlay visible={loading} overlayblur={2} />
            <GenericModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <WeatherModal
                    weatherData={allWeatherData}
                    closeModal={() => setIsModalOpen(false)}
                    currentWeather={currentWeather}
                    currentDayWeather={currentDayWeather}
                />
            </GenericModal >
        </div>
    )
}

export default WeatherSection