import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import CropDetails from "./pages/CropDetails/CropDetails";
import Verification from "./pages/Verification/Verification";
import Videos from "./pages/Videos/Videos";
import PersonalInfo from "./pages/PersonalInfo/PersonalInfo";
import Navigation from "./components/Navigation/Navigation";
import Header from "./components/Header/Header";
import CropDetailsSolution from "./pages/CropDetailsSolution/CropDetailsSolution";
import OTP from "./pages/OTP/OTP";
import ConfirmSubscription from "./pages/ConfirmSubscription/ConfirmSubscription";
import Sidebar from "./components/Sidebar/Sidebar";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CheckUser from "./pages/CheckUser/CheckUser";
import Subscription from "./pages/Subscription/Subscription";
import Protected from "./components/Wrappers/Protected";
import DummyHome from "./pages/DummyHome";
import Unprotected from "./components/Wrappers/Unprotected";
import YourCrops from "./pages/YourCrops";
import { closeDrawer, toggle } from "./components/Sidebar/store";
import VideoPlayer from "./pages/VideoPlayer";
import ErrorPage from "./pages/ErrorPage";
import { AuthProvider } from "./utils/AuthContext";
import CropSection from "./pages/Home/CropSection";

const publicRoutes = [
  {
    path: "/",
    elem: <DummyHome />,
  },
  {
    path: "/authentication",
    elem: <Verification />,
  },
  {
    path: "/verification",
    elem: <CheckUser />,
  },
  {
    path: "/verification/otp-process",
    elem: <OTP />,
  },
];

const privateRoutes = [
  {
    path: "/home",
    elem: <Home />,
  },
  {
    path: "/crop",
    elem: <CropSection />,
  },
  {
    path: "/your-crops",
    elem: <YourCrops />,
  },
  {
    path: "/crop-details/:id",
    elem: <CropDetails />,
  },
  {
    path: "/crop-details/solution/:id",
    elem: <CropDetailsSolution />,
  },
  {
    path: "/personal-info",
    elem: <PersonalInfo />,
  },
  {
    path: "/subscription",
    elem: <Subscription />,
  },
  {
    path: "/confirm-subscription",
    elem: <ConfirmSubscription />,
  },
  {
    path: "/videos",
    elem: <Videos />,
  },
  {
    path: "/video-player",
    elem: <VideoPlayer />,
  },
  {
    path: "/error-page",
    elem: <ErrorPage />,
  }
];

function App() {

  const location = useLocation();
  const dispatch = useDispatch();

  const [noHNRoutes, setNoHNRoutes] = useState({
    isProfileRoute: false,
    isCropDetailsRoute: false,
    isSubscriptionConfirmationRoute: false,
    isVerificationRoute: false,
    isSubscriptionRoute: false,
    isYourCropsRoute: false,
    isRootRoute: false,
  });

  const isProfileRoute = useRef(false);
  const isCropDetailsRoute = useRef(false);
  const isSubscriptionConfirmationRoute = useRef(false);
  const isVerificationRoute = useRef(false);
  const isSubscriptionRoute = useRef(false);
  const isYourCropsRoute = useRef(false);
  const isVideoPlayerRoute = useRef(false);
  const isErrorRoute = useRef(false);

  useEffect(() => {
    const handleRoutes = () => {
      isProfileRoute.current = location.pathname.startsWith("/personal-info");
      isCropDetailsRoute.current =
        location.pathname.startsWith("/crop-details/");
      isSubscriptionConfirmationRoute.current = location.pathname.startsWith(
        "/confirm-subscription"
      );
      isVerificationRoute.current =
        location.pathname.startsWith("/verification");
      isSubscriptionRoute.current =
        location.pathname.startsWith("/subscription");
      isYourCropsRoute.current = location.pathname.startsWith("/your-crops");
      isVideoPlayerRoute.current = location.pathname.startsWith("/video-player");
      isErrorRoute.current = location.pathname.startsWith("/error-page");
      const isRootRoute = location.pathname === "/";

      setNoHNRoutes({
        isProfileRoute: isProfileRoute.current,
        isCropDetailsRoute: isCropDetailsRoute.current,
        isSubscriptionConfirmationRoute:
          isSubscriptionConfirmationRoute.current,
        isVerificationRoute: isVerificationRoute.current,
        isSubscriptionRoute: isSubscriptionRoute.current,
        isYourCropsRoute: isYourCropsRoute.current,
        isVideoPlayerRoute: isVideoPlayerRoute.current,
        isErrorRoute: isErrorRoute.current,
        isRootRoute: isRootRoute,
      });
    };

    handleRoutes();
  }, [location.pathname]);

  const isDrawerOpen = useSelector((state) => state.Sidebar.value);

  const [isSidebarOpen, setSidebarOpen] = useState(isDrawerOpen);

  const toggleSidebar = () => {
    dispatch(toggle());
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    dispatch(closeDrawer());
    setSidebarOpen(false);
  };

  const showHeader =
    !noHNRoutes.isRootRoute &&
    !noHNRoutes.isSubscriptionRoute &&
    !noHNRoutes.isVerificationRoute &&
    !noHNRoutes.isProfileRoute &&
    !noHNRoutes.isCropDetailsRoute &&
    !noHNRoutes.isSubscriptionConfirmationRoute &&
    !noHNRoutes.isYourCropsRoute &&
    !noHNRoutes.isVideoPlayerRoute &&
    !noHNRoutes.isErrorRoute;
  // subscriptionStore?.subscriberDetails?.data?.isSubscriber !== "N";
  const showNavigation =
    !noHNRoutes.isRootRoute &&
    !noHNRoutes.isSubscriptionRoute &&
    !noHNRoutes.isVerificationRoute &&
    !noHNRoutes.isProfileRoute &&
    !noHNRoutes.isCropDetailsRoute &&
    !noHNRoutes.isSubscriptionConfirmationRoute &&
    !noHNRoutes.isYourCropsRoute &&
    !noHNRoutes.isVideoPlayerRoute &&
    !noHNRoutes.isErrorRoute;
  // subscriptionStore?.subscriberDetails?.data?.isSubscriber !== "N";
  return (
    <AuthProvider>
      <div className="App">
        {/* <Header /> */}
        {showHeader && <Header toggleSidebar={toggleSidebar} />}

        {/* <Button onClick={toggleDrawer(true)} className="text-green-600">Open drawer</Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)} className="w-[20rem]">
        <Sidebar2 closeSidebar={() => toggleDrawer(false)} />
      </Drawer> */}
        {/* makes the background screen dim + css applied by z-index numbering */}
        {isSidebarOpen && showHeader && <div className="overlay" onClick={closeSidebar}></div>}
        {showHeader && (
          <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
        )}

        {/* Main content */}
        <div className="content">
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<Unprotected>{route.elem}</Unprotected>}
              />
            ))}

            {privateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<Protected>{route.elem}</Protected>}
              />
            ))}

            <Route path="*" element={<ErrorPage />} />

          </Routes>
        </div>

        {/* Navigation bar */}
        {showNavigation && <Navigation />}
      </div>
    </AuthProvider>
  );
}

export default App;
