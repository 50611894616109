import React from 'react'
import BackHeader from '../../components/BackHeader'
import { Navigate, useLocation } from 'react-router-dom';

const VideoPlayer = () => {
    const location = useLocation().state;
    if (!location) return <Navigate to="/home" />;
    return (
        <div>
            <BackHeader name={"ویڈیو"} />

            <div className="">
                <video
                    src={location?.videoSrc}
                    className=" w-full"
                    controls
                    poster={location?.details?.thumbnail}
                    autoPlay
                ></video>

                <div className="mt-2 mx-3 flex flex-row-reverse justify-between items-center">
                    <h3 className="text-xl mb-3">{location?.title}</h3>
                    <p className="bg-[#d4f0e3] px-4 w-16 h-8 text-[14px] flex justify-center items-center rounded-full">{location?.tag}</p>
                    {/* <div className="flex justify-between items-center">
                        <span className="text-sm">{location?.details?.time}</span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer