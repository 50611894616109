// import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// import { getSubscriberDetails } from '../../pages/Subscription/store';
// import { subscribeUser } from '../../API';

const Protected = ({ children }) => {
    const authStore = useSelector((state) => state.auth);
    // console.log("Auth Store: ", authStore.authData)
    // const dispatch = useDispatch();
    // useLayoutEffect(() => {
    //     const getUser = async () => {
    //         const res = await dispatch(getSubscriberDetails({ cellno: authStore?.authData?.msisdn })).unwrap();
    //         if (res.data?.isSubscriber === "N") {
    //             let data = {
    //                 subMode: "WEBVIEW",
    //                 msisdn: authStore?.authData?.msisdn,
    //             }
    //             subscribeUser(data)
    //                 .then((res) => {
    //                     dispatch(getSubscriberDetails({ cellno: authStore?.authData?.msisdn }))
    //                 })
    //                 .catch((err) => {
    //                     console.log("Subscription Error: ", err)
    //                 })
    //         }
    //     }
    //     if (!authStore.authData) return;
    //     getUser();
    // }, [])
    if (!authStore.authData || Object.keys(authStore.authData).length === 0) return <Navigate to="/verification" />
    return children;
};
export default Protected;