import React, { useState } from 'react'
import adImage from "../../assets/images/ad_placementt.png";
import { Modal } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import LocationSection from './components/LocationSection';
import WeatherSection from './components/WeatherSection';
import CropSection from './components/CropSection';
import VideosSection from './components/VideosSection';
import Navigation from './components/Navigation';

const DummyHome = () => {
    const navigate = useNavigate();
    const [confirmationModal, setConfirmationModal] = useState(false)
    const openLoginModal = () => setConfirmationModal(true)
    return <Navigate to="/verification" />
    return (
        <>
            <div className='crops-container'>
                <Header openLoginModal={openLoginModal} />
                <LocationSection openLoginModal={openLoginModal} />
                <WeatherSection />
                <CropSection openLoginModal={openLoginModal} />
                {/* Ad Placement start */}
                <div className="ad-section bg-white mt-4">
                    <img src={adImage} alt="ad" className="ad" />
                </div>
                {/* Ad Placement end */}
                <VideosSection openLoginModal={openLoginModal} />
            </div>
            <Navigation openLoginModal={openLoginModal} />

            {/* Go to login page modal start */}
            <Modal
                show={confirmationModal}
                onClose={() => setConfirmationModal(false)}
                onHide={() => setConfirmationModal(false)}
                size="sm"
                centered
                className="confirmations-modal"
            >
                <Modal.Body>
                    <div className="flex flex-col items-center">
                        <p className="description leading-[45px] tracking-tight">!آپ کو پہلے لاگ ان کرنا ہوگا</p>
                        <button className="w-100 modal-btn" onClick={() => navigate('/verification')}>ٹھیک ہے</button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Go to login page modal end */}
        </>
    )
}

export default DummyHome