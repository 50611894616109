import { Modal } from "react-bootstrap";
import { getVimeoThumbnail, getVimeoVideoIdFromUrl } from "../../utils/extras";
import "./HomeVideo.css"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HomeVideo = ({ videoSrc, title, tag, openLoginModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState({
    thumbnail: null,
    time: "",
  })
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("Detail: ", item)
    const videoId = getVimeoVideoIdFromUrl(videoSrc);
    getVimeoThumbnail(videoId).then((details) => {
      // console.log("THUMBNAIL", thumbnail)

      let time = Math.floor(details.time / 60) + ":" + (details.time % 60 < 10 ? "0" + details.time % 60 : details.time % 60);
      setDetails({
        thumbnail: details.thumbnail,
        time
      })
    })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, [])


  const handlePlayClick = () => {
    if (openLoginModal) {
      openLoginModal();
      return;
    }
    navigate('/video-player', { state: { videoSrc, title, tag, details } });
  };
  return (
    <>
      <div className="video-card">
        <div className="video-wrapper">
          <div className="relative">
            <div className="play-button h-full rounded-lg">
              <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3103_4671)">
                  <path d="M26 4.33301C14.04 4.33301 4.33331 14.0397 4.33331 25.9997C4.33331 37.9597 14.04 47.6663 26 47.6663C37.96 47.6663 47.6666 37.9597 47.6666 25.9997C47.6666 14.0397 37.96 4.33301 26 4.33301ZM21.6666 35.7497V16.2497L34.6666 25.9997L21.6666 35.7497Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_3103_4671">
                    <rect width="52" height="52" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <video
              onClick={handlePlayClick}
              className="w-full object-cover rounded-lg cursor-pointer"
              poster={details?.thumbnail || "/dummy-farm.jpg"}
            ></video>
          </div>
        </div>

        <div className="video-info">
          <h3 className="video-title">{title}</h3>
          <div className="video-meta">
            <span className="video-time">{details?.time}</span>
            <p className="bg-[#E3F9EF] px-4 w-16 h-8 text-[14px] flex justify-center items-center rounded-full">{tag}</p>
          </div>
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onHide={() => setIsModalOpen(false)}
        size="sm"
        centered
        className="confirmations-modal"
      >
        <Modal.Body>
          <div className="flex flex-col items-center">
            <p className="description leading-[45px] tracking-wide">آپ کو یہ ویڈیو دیکھنے کے لئے سبسکرائب کرنا ہوگا</p>
            <button className="w-100 modal-btn" onClick={() => navigate('/subscription')}>ٹھیک ہے</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default HomeVideo;