import "./Navigation.css";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  const [comingSoonModal, setComingSoonModal] = useState(false)
  const [activeTab, setActiveTab] = useState("home");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  }

  useEffect(() => {
    handleTabChange(location.pathname.split("/").pop());
  }, [location.pathname]);

  const authStore = useSelector((state) => state.auth);
  if (!authStore.authData || Object.keys(authStore.authData).length === 0) return null;
  return (
    <>
      <div className="navigation">
        <Link
          to="/home"
          onClick={() => handleTabChange("home")}
          className={`crops-tab ${activeTab === "home" ? "crops-active-tab" : "crops-inactive-tab"}`}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.68701 1.26164C6.30151 0.897163 5.69849 0.897163 5.31299 1.26164L0.46948 5.84098C0.169802 6.12431 0 6.51853 0 6.93094V12.5002C0 13.3286 0.671572 14.0002 1.5 14.0002H3C3.82843 14.0002 4.5 13.3286 4.5 12.5002V10.0002C4.5 9.72407 4.72386 9.50021 5 9.50021H7C7.27614 9.50021 7.5 9.72407 7.5 10.0002V12.5002C7.5 13.3286 8.17157 14.0002 9 14.0002H10.5C11.3284 14.0002 12 13.3286 12 12.5002V6.93094C12 6.51853 11.8302 6.12431 11.5305 5.84098L6.68701 1.26164Z" fill={`${activeTab === "home" ? "white" : "#0F2532"}`} />
            </svg>

            <h4 className={`text-[14px] ${`${activeTab === "home" ? "text-white" : "text-[#0F2532]"}`} `}>فصلیں</h4>
          </div >
        </Link >

        <Link
          // style={{ opacity: "0.5" }}
          // to="/rates"
          onClick={() => {
            // handleTabChange("rates")
            setComingSoonModal(true)
          }}
          className={`rates-tab ${activeTab === "rates" ? "rates-active-tab" : "rates-inactive-tab"}`}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 0C0.671573 0 0 0.671573 0 1.5V8.5C0 9.32843 0.671573 10 1.5 10H12.5C13.3284 10 14 9.32843 14 8.5V1.5C14 0.671573 13.3284 0 12.5 0H1.5ZM4 1V2C4 3.10457 3.10457 4 2 4L1 4V3H2C2.55228 3 3 2.55228 3 2V1H4ZM7 6.75C6.0335 6.75 5.25 5.9665 5.25 5C5.25 4.0335 6.0335 3.25 7 3.25C7.9665 3.25 8.75 4.0335 8.75 5C8.75 5.9665 7.9665 6.75 7 6.75ZM1 7V6H2C3.10457 6 4 6.89543 4 8V9H3V8C3 7.44772 2.55228 7 2 7H1ZM12 7C11.4477 7 11 7.44772 11 8V9H10V8C10 6.89543 10.8954 6 12 6H13V7H12ZM12 3H13V4L12 4C10.8954 4 10 3.10457 10 2V1H11V2C11 2.55228 11.4477 3 12 3ZM15.0001 8.49997C15.0001 9.88069 13.8808 11 12.5001 11H2.08545C2.29137 11.5826 2.84699 12 3.5001 12H12.5001C14.4331 12 16.0001 10.433 16.0001 8.49997V3.49997C16.0001 2.84686 15.5827 2.29125 15.0001 2.08533V8.49997Z" fill={`${activeTab === "rates" ? "white" : "#0F2532"}`} />
            </svg>
            <h4 className={`text-[14px] ${`${activeTab === "rates" ? "text-white" : "text-[#0F2532]"}`} `}>ریٹ</h4>
          </div>
        </Link>

        <Link
          // style={{ opacity: "0.5" }}
          // to="/store"
          onClick={() => {
            // handleTabChange("store")
            setComingSoonModal(true)
          }}
          className={`store-tab ${activeTab === "store" ? "store-active-tab" : "store-inactive-tab"}`}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 7V5H12V7H4ZM10 11V10H12V11H10ZM16 1.5C16 2.15311 15.5826 2.70873 15 2.91465V13.5C15 13.7761 14.7761 14 14.5 14H8V9.5C8 9.22386 7.77614 9 7.5 9H3.5C3.22386 9 3 9.22386 3 9.5V14H1.5C1.22386 14 1 13.7761 1 13.5V2.91465C0.417404 2.70873 0 2.15311 0 1.5C0 0.671573 0.671573 0 1.5 0H14.5C15.3284 0 16 0.671573 16 1.5ZM1 1.5C1 1.77614 1.22386 2 1.5 2H14.5C14.7761 2 15 1.77614 15 1.5C15 1.22386 14.7761 1 14.5 1H1.5C1.22386 1 1 1.22386 1 1.5ZM3.5 4C3.22386 4 3 4.22386 3 4.5V7.5C3 7.77614 3.22386 8 3.5 8H12.5C12.7761 8 13 7.77614 13 7.5V4.5C13 4.22386 12.7761 4 12.5 4H3.5ZM9.5 9C9.22386 9 9 9.22386 9 9.5V11.5C9 11.7761 9.22386 12 9.5 12H12.5C12.7761 12 13 11.7761 13 11.5V9.5C13 9.22386 12.7761 9 12.5 9H9.5ZM4 14H7V10H4V14Z" fill={`${activeTab === "store" ? "white" : "#0F2532"}`} />
            </svg>
            <h4 className={`text-[14px] ${`${activeTab === "store" ? "text-white" : "text-[#0F2532]"}`} `}>پرچون</h4>
          </div>
        </Link>

        <Link
          to="/videos"
          onClick={() => handleTabChange("videos")}
          className={`videos-tab ${activeTab === "videos" ? "videos-active-tab" : "videos-inactive-tab"}`}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 0C1.11929 0 0 1.11929 0 2.5V11.5C0 12.8807 1.11929 14 2.5 14H13.5C14.8807 14 16 12.8807 16 11.5V2.5C16 1.11929 14.8807 0 13.5 0H2.5ZM6.76512 4.07608L10.7651 6.57764C10.9093 6.6678 10.9978 6.82505 11 6.99508C11.0022 7.1651 10.9178 7.3246 10.776 7.41847L6.77603 10.0669C6.62252 10.1685 6.42557 10.1776 6.26337 10.0905C6.10118 10.0033 6 9.83412 6 9.65001V4.5C6 4.31821 6.09867 4.15075 6.25768 4.06264C6.41669 3.97454 6.61099 3.97969 6.76512 4.07608Z" fill={`${activeTab === "videos" ? "white" : "#0F2532"}`} />
            </svg>

            <h4 className={`text-[14px] ${`${activeTab === "videos" ? "text-white" : "text-[#0F2532]"}`} `}>ویڈیوز</h4>
          </div>
        </Link>
      </div>

      <Modal
        show={comingSoonModal}
        onClose={() => setComingSoonModal(false)}
        onHide={() => setComingSoonModal(false)}
        centered
        className="confirmations-modal"
        size="sm"
      >
        <Modal.Body>
          <div className="flex flex-col items-center">
            <p className="description leading-[45px] max-w-96">یہ سہولت جلد میسّر کی جائے گی۔</p>
            <button className="w-100 modal-btn max-w-96" onClick={() => setComingSoonModal(false)}>ٹھیک ہے</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Navigation