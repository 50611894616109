import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setAddCropsDrawer, setSelectedCrops, setSowingDateModal, setTempCrop, updateActiveCropSowingDt, updateSubcriberCrops } from '../store';
import { formatDateWithUrduMonth } from '../../../../utils/extras';
import { applyInterFontToDigits } from '../../../CropDetails/CropDetails';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { updateSubcriberCrop } from '../../../../API';
import moment from 'moment';
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// Define the range function
const range = (start, end) => {
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
};

const years = range(1990, 2040);

// Define the months array
const months = [
    "جنوری",
    "فروري",
    "مارچ",
    "اپريل",
    "مئ",
    "جون",
    "جولائ",
    "اگست",
    "ستمبر",
    "اکتوبر",
    "نومبر",
    "دسمبر",
];

// Define the getYear function
const getYear = (date) => date.getFullYear();

// Define the getMonth function
const getMonth = (date) => date.getMonth();


const SowingDateDrawer = ({ cellNo, rerender, setRerender }) => {
    const dispatch = useDispatch()
    const cropStore = useSelector(state => state.CropsStore)
    const tempCrop = cropStore.tempCrop

    const [sowingDate, setSowingDate] = useState(new Date())

    useEffect(() => {
        if (tempCrop.sowingDt) setSowingDate(new Date(tempCrop.sowingDt))
    }, [])

    const handleDate = (date) => {
        let crop = {
            ...tempCrop,
            sowingDt: date
        }
        let crops = [
            ...(cropStore.selectedCrops || []),
            crop,
        ]
        dispatch(setSelectedCrops(crops))
        dispatch(setSowingDateModal(false))
        dispatch(setAddCropsDrawer(true))
    }

    const handleUpdateDate = (date) => {
        if (cellNo === "03400000000") {
            let subCrops = JSON.parse(JSON.stringify(cropStore.subscriberCrops))
            let updatedSubCrops = subCrops.map(c => {
                if (c.cropId === cropStore.activeCrop.cropId) {
                    c.sowingDt = date
                    return c
                }
                return c
            })
            dispatch(updateSubcriberCrops(updatedSubCrops))
            dispatch(setSowingDateModal(false))
            dispatch(updateActiveCropSowingDt(date))
            setRerender(!rerender)
            return;
        }
        updateSubcriberCrop(tempCrop.cropId, cellNo, moment(date).format("YYYY-MM-DD"))
            .then(res => {
                // console.log("update sowingdate ", res.data)
                dispatch(setSowingDateModal(false))
                dispatch(updateActiveCropSowingDt(date))
                setRerender(!rerender)
            })
            .catch(err => console.log("update sowingdate err", err))
    }

    return (
        <div>
            <header className='text-right flex items-center gap-2'>
                <h4 className="flex-1 text-[16px] leading-8 tracking-wider">بہتر رہنمائی کے لئے {tempCrop?.urduName} کی بوائی کی تاریخ منتخب کریں۔</h4>
                <img
                    src={`${baseURL}/uploads/${tempCrop?.image}`}
                    alt={tempCrop?.image}
                    className='rounded-full'
                    width={42}
                    height={42}
                />
            </header>

            <body>
                <div className="flex flex-row-reverse justify-center items-center gap-2 border py-2 rounded-[2px]">
                    <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_3108_8088)">
                            <path
                                d="M13.1667 3.16634H12.5V1.83301H11.1667V3.16634H5.83333V1.83301H4.5V3.16634H3.83333C3.09333 3.16634 2.50667 3.76634 2.50667 4.49967L2.5 13.833C2.5 14.5663 3.09333 15.1663 3.83333 15.1663H13.1667C13.9 15.1663 14.5 14.5663 14.5 13.833V4.49967C14.5 3.76634 13.9 3.16634 13.1667 3.16634ZM13.1667 13.833H3.83333V7.16634H13.1667V13.833ZM6.5 9.83301H5.16667V8.49967H6.5V9.83301ZM9.16667 9.83301H7.83333V8.49967H9.16667V9.83301ZM11.8333 9.83301H10.5V8.49967H11.8333V9.83301ZM6.5 12.4997H5.16667V11.1663H6.5V12.4997ZM9.16667 12.4997H7.83333V11.1663H9.16667V12.4997ZM11.8333 12.4997H10.5V11.1663H11.8333V12.4997Z"
                                fill="#484848"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_3108_8088">
                                <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <h3
                        style={{
                            whiteSpace: "normal",
                            direction: "rtl",
                            fontSize: "16px",
                        }}
                        className='mt-1'
                        dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(sowingDate)) }}>
                    </h3>
                </div>

                
                <DatePicker
                    inline
                    showIcon
                    toggleCalendarOnIconClick
                    calendarStartDay={1}
                    selected={sowingDate}
                    onChange={(date) => setSowingDate(date)}
                    renderCustomHeader={({ date, changeYear, changeMonth }) => <CustomDateHeader date={date} changeYear={changeYear} changeMonth={changeMonth} />}
                >
                    <div className="flex flex-row-reverse gap-2">
                        <button
                            className={`w-full bg-[#007F3B] rounded text-white text-[18px] pt-2 flex items-center justify-center active:translate-y-[1px]`}
                            onClick={() => {
                                tempCrop.sowingDt ?
                                    handleUpdateDate(sowingDate || new Date()) :
                                    handleDate(sowingDate || new Date())
                            }}
                        >
                            <p>منتخب کریں</p>
                        </button>
                        <button
                            className="w-full bg-black rounded text-white text-[18px] flex items-center justify-center active:translate-y-[1px]"
                            onClick={() => {
                                tempCrop.sowingDt ?
                                    dispatch(setSowingDateModal(false)) :
                                    handleDate(new Date())
                            }}
                        >
                            <p>بعد میں</p>
                        </button>
                    </div>
                </DatePicker>
            </body>
        </div>
    )
}

const CustomDateHeader = ({ date, changeYear, changeMonth }) => (
    <div
        style={{
            margin: 5,
            padding: 5,
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
        }}
    >
        <select
            className="digit"
            style={{
                fontfamily: "inter",
                width: "50%",
                height: "50px",
                textAlign: "center",
                verticalAlign: "middle",
                paddingTop: "10px",
                fontSize: "18px",
                borderRadius: "5px",
                direction: "rtl",
            }}
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
        >
            {years.map((option) => (
                <option className="digit" key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>

        <select
            style={{
                width: "50%",
                height: "50px",
                verticalAlign: "middle",
                textAlign: "center",
                fontSize: "14px",
                borderRadius: "5px",
                direction: "rtl",
                boxSizing: "border-box",
            }}
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
            }
        >
            {months.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    </div>
)

export default SowingDateDrawer